<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <AdminMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">

<h2 class="pink">To Reply</h2>
                  <br><br><br>

							<div class="shadow padded">
		                    	<ul class="flirt-list">
                            <li v-for="message in messages" :key="message.uuid">

                            <div class="row">
				                                    <p class="time">
				                                    	{{ formatDate(message.createdAt) }}
				                                    </p>
                              <div class="fl2">
				                            			<img v-if="message.memberFromDto.image" class="chat-profile-photo rounded" :src="'/uploads/users/' + message.memberFromDto.image">
                                          <img v-else class="chat-profile-photo rounded" src="/global/img/thumb-l.gif">
                                          <div class="fl3">
				                                	<p class="username">
				                                		<router-link :to="'/admin/to-reply/conversations/' + message.conversation">
				                                			{{ message.memberFromDto.name }}
				                                		</router-link>
				                                	</p>
				                                </div></div>


                                        <div class="fl2">
				                            			<img v-if="message.memberToDto.image" class="chat-profile-photo rounded" :src="'/uploads/150/' + message.memberToDto.image">
                                          <img v-else class="chat-profile-photo rounded" src="/global/img/thumb-l.gif">
                                          <div class="fl3">
				                                	<p class="username">
				                                		<router-link :to="'/admin/to-reply/conversations/' + message.conversation">
				                                			{{ message.memberToDto.name }}
				                                		</router-link>
				                                	</p>
				                                </div>
				                            	</div>
                                    </div>
				                                <div class="fl4">
				                                															{{ message.body }}
																	                                </div>
                                                                  <div class="fl5 mobile-hide">
				                                						                                	<router-link v-if="message.attachmentDto" :to="'/admin/to-reply/conversations/' + message.conversation">
					                                		<img src="/theme/001/images/icon_attachment.png">
					                                	</router-link>
				                                					                                </div>
				                                <div class="fl6">
				                                					                                </div>
				                                <div class="fl7">
				                                	<router-link class="btn mobile-hide" :to="'/admin/to-reply/conversations/' + message.conversation">
				                                		View
				                                	</router-link>
				                                	<router-link class="mobile-only" :to="'/admin/to-reply/conversations/' + message.conversation">
			                                			View
			                                		</router-link>
				                                </div>
                            </li>

		                        </ul>

		                    </div>

		                    <div class="row">
		                        <div class="col-md-12">
                              <div class="pager center">
                <div class="pagination">
                  <ul>
                    <li :class="{ 'disabled': currentPage == 1 }">
                      <router-link v-if="currentPage > 1" :to="'/admin/to-reply?page=' + (currentPage - 1)"
                        rel="prev">&laquo;</router-link>
                      <span v-else>&laquo;</span>
                    </li>
                    <li v-for="n in pagesCount" :key="n" :class="{ 'active': n == currentPage }">
                      <router-link v-if="n != currentPage" :to="'/admin/to-reply?page=' + n">{{ n }}</router-link>
                      <span v-else>{{ n }}</span>
                    </li>
                    <li :class="{ 'disabled': currentPage == pagesCount }">
                      <router-link v-if="currentPage < pagesCount" :to="'/admin/to-reply?page=' + (currentPage + 1)"
                        rel="next">&raquo;</router-link>
                      <span v-else>&raquo;</span>
                    </li>
                  </ul>
                </div>
              </div>
		                        </div>
		                        </div>


</div>
      </div>
    </div>
  </div>
  <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
</div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import AdminMenu from '@/app/components/AdminMenu';
import { useUrl } from '@/app/router';
import { fetchToReplyConversations } from '@/app/api/message';
import { useQuery } from '@/app/use/router';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  name: 'ToReplyList',
  components: { Header, Footer, AdminMenu },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { currentPath } = useUrl();

    const route = useRoute();
    const { pagination } = useQuery();
    const messages = ref([]);
    const totalItems = ref(0);
    const pagesCount = ref(1);
    const currentPage = ref(1);

    const reloadEmojiScript = function () {
        document.getElementById('emoji-script')?.remove();
        const emojiPlugin = document.createElement('script');
        emojiPlugin.src = '/global/emoji/jquery.emoji.js';
        emojiPlugin.id = 'emoji-script';
        emojiPlugin.async = true;
        document.body.appendChild(emojiPlugin);
    };

    const loadMessages = () => {
      pagination.value.rowsPerPage = 10;
      fetchToReplyConversations({
        pagination: pagination.value,
      })
        .then(async data => {
          messages.value = data.items;
          totalItems.value = data.total;
          pagesCount.value = data.pagesCount;
          currentPage.value = data.page;
          reloadEmojiScript();
        });
    };

    loadMessages();

    watch(
      () => route.query.page,
      async () => {
        loadMessages();
      },
      { immediate: true }
    );

    const formatDate = (dateString) => {
      const date = new Date(Date.parse(dateString));

        return moment(date).format("Do MMMM HH:mm");

    };

    return {
      currentPath,
      messages,
      totalItems,
      pagesCount,
      currentPage,
      pagination,
      formatDate
    };
  },
};
</script>
