<template>
    <div class="col-md-3">
        <div id="slidingMenu">
            <div class="shadow divmenu">
                <ul class="side-navigation">
                    <li :class="{ 'active': currentPath == '/admin/to-reply' }">
                        <router-link to="/admin/to-reply">
                            <i class="my-mailbox"></i> ToReply
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/" @click="logout">
                            <i class="logout"></i> Logout
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div><!--/col-md-3-->
</template>
    
<script>
import { useUser } from '@/app/use/user';
import { useUrl } from '@/app/router';

export default {
    name: 'AdminMenu',
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
    },
    mounted: function () {
        document.getElementById('jquery-extra-script')?.remove();
        const jqueryExtraPlugin = document.createElement('script');
        jqueryExtraPlugin.src = '/theme/001/js/jquery.extra.min.js';
        jqueryExtraPlugin.id = 'jquery-extra-script';
        jqueryExtraPlugin.async = true;
        document.head.appendChild(jqueryExtraPlugin);
    },
    setup() {
        const { logout } = useUser();
        const { currentPath } = useUrl();

        return { currentPath, logout };
    },
};
</script>
    